// src/components/LanguageSwitcher.jsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './LanguageSwitcher.css'; // Asegúrate de importar tu CSS

// Importa las imágenes
import spainFlag from './spain-flag.png';
import ukFlag from './uk-flag.png';

const LanguageSwitcher = () => {
  const navigate = useNavigate();

  const handleLanguageChange = (lang) => {
    if (lang === 'es') {
      window.location.href = 'https://www.tacpdf.com'; // Redirige a la versión en español
    } else if (lang === 'en') {
      window.location.href = 'https://en.tacpdf.com'; // Redirige a la versión en inglés
    }
  };

  return (
    <div className="language-switcher">
      <img
        src={spainFlag}
        alt="Español"
        onClick={() => handleLanguageChange('es')}
        className="flag-icon"
      />
      <img
        src={ukFlag}
        alt="English"
        onClick={() => handleLanguageChange('en')}
        className="flag-icon"
      />
    </div>
  );
};

export default LanguageSwitcher;
