import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [step, setStep] = useState(1); // 1: Enviar código, 2: Confirmar nueva contraseña
  const [code, setCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSendCode = async (e) => {
    e.preventDefault();
    try {
      await Auth.forgotPassword(email);
      setStep(2);
    } catch (err) {
      setError(err.message);
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      await Auth.forgotPasswordSubmit(email, code, newPassword);
      alert('Contraseña restablecida exitosamente');
      navigate('/login'); // Redirige al login después de restablecer la contraseña
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div>
      <h2>Olvidó su contraseña?</h2>
      {step === 1 && (
        <form onSubmit={handleSendCode}>
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <button type="submit">Enviar código</button>
          {error && <p className="error-message">{error}</p>}
        </form>
      )}
      {step === 2 && (
        <form onSubmit={handleResetPassword}>
          <label htmlFor="code">Código de verificación:</label>
          <input
            type="text"
            id="code"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            required
          />
          <label htmlFor="new-password">Nueva conttraseña:</label>
          <input
            type="password"
            id="new-password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
          <button type="submit">Restablecer contraseña</button>
          {error && <p className="error-message">{error}</p>}
        </form>
      )}
    </div>
  );
};

export default ForgotPassword;
