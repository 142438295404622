import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AWS from 'aws-sdk';
import { PDFDocument } from 'pdf-lib';
import './HomePage.css'; // Importa el archivo CSS
import logo from './herramienta_extraccion_texto_pdf.png'; 
import LanguageSwitcher from './LanguageSwitcher';

function HomePage() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [isFileValid, setIsFileValid] = useState(true);
  const [isValidating, setIsValidating] = useState(false);
  const [showWarning, setShowWarning] = useState(false); // Estado para mostrar el aviso
  const navigate = useNavigate();

  const validateFile = async (file) => {
    setIsValidating(true);
    try {
      const arrayBuffer = await file.arrayBuffer();
      const pdfDoc = await PDFDocument.load(arrayBuffer);
      const numPages = pdfDoc.getPageCount();
      console.log('Número de páginas:', numPages);

      if (numPages > 15) {
        alert('El archivo supera el límite de 15 páginas. Por favor, seleccione otro archivo.');
        setIsFileValid(false);
      } else {
        setIsFileValid(true);
        if (numPages <= 5) {
          await handleUpload(file, numPages);
        } else {
          setShowWarning(true); // Muestra el aviso para archivos grandes
          setUploading(false);
        }
      }
    } catch (err) {
      console.error('Error al leer el archivo PDF:', err);
      alert('No se pudo leer el archivo PDF. Por favor, intente con otro archivo.');
      setIsFileValid(false);
    } finally {
      setIsValidating(false);
    }
  };

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      setIsFileValid(true);
      setShowWarning(false); // Oculta el aviso cuando se selecciona un nuevo archivo
      validateFile(file);
    }
  };

  const handleUpload = async (file, numPages) => {
    if (!file || !isFileValid || uploading) {
      return;
    }

    setUploading(true);

    AWS.config.update({
      accessKeyId: 'AKIA42J7OELPXKHF6YML', // Reemplaza con tu accessKeyId
      secretAccessKey: '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', // Reemplaza con tu secretAccessKey
      region: 'us-east-1',
    });

    const s3 = new AWS.S3();

    const uploadFile = async (file, key) => {
      const params = {
        Bucket: 'pdfextracttools',
        Key: key,
        Body: file,
        ContentType: file.type,
      };

      try {
        await s3.upload(params).promise();
        console.log('Archivo cargado con éxito:', key);
        return `https://s3.amazonaws.com/${params.Bucket}/${params.Key}`;
      } catch (err) {
        console.error('Error al cargar el archivo a S3:', err);
        alert('Hubo un error al cargar el archivo.');
      }
    };

    const fileURL = await uploadFile(file, file.name);

    if (numPages <= 5) {
      navigate('/extracttextpage', { state: { fileURL, numPages } });
    } else {
      navigate('/login', { state: { fileURL, numPages } });
    }

    setUploading(false);
    setSelectedFile(null);
  };

  return (
    <div className="home-container">
      <div>
        <LanguageSwitcher /> 
      </div>
      <img src={logo} alt="Logo de la herramienta de extracción de texto de PDFs" className="logo" /> {/* Inserta el logotipo */}
      <h1>¡Hola!</h1>
      <p>
        Convierte tus archivos PDF escaneados en texto de manera fácil y rápida. ¡Solo sube tu archivo y deja que nosotros
        nos encarguemos del resto!
      </p>
      
      <div className="file-upload-section">
        <input
          id="fileInput"
          type="file"
          onChange={handleFileSelect}
          disabled={uploading || isValidating}
          style={{ display: 'none' }} // Oculta el input
        />
        <label htmlFor="fileInput" className="file-upload-button">
          {selectedFile ? selectedFile.name : 'Seleccionar archivo'}
        </label>
      </div>
      {selectedFile && <div className="file-preview">{selectedFile.name}</div>}
      <button
        className="upload-button"
        onClick={() => handleUpload(selectedFile)}
        disabled={uploading || !selectedFile || !isFileValid || isValidating}
      >
        {uploading ? 'Cargando...' : 'Subir archivo'}
      </button>
      
      {showWarning && (
        <div className="warning-message">
          <p>
            El archivo tiene más de 5 páginas. Para continuar con la extracción del texto, por favor, haz click en "Subir Archivo" y luego inicia sesión.
          </p>
        </div>
      )}

      <p>
        <Link to="/login" className="login-link">
          Iniciar sesión / Registro
        </Link>
      </p>
    </div>
  );
}

export default HomePage;
